import { Component, Input, OnInit } from '@angular/core';
import { QueryParamsService } from '../../services/query-params.service';
import { Pagination } from '../../models/Pagination.model';

@Component({
  selector: 'app-common-pagination',
  templateUrl: './common-pagination.component.html',
  styleUrls: ['./common-pagination.component.scss']
})
export class CommonPaginationComponent implements OnInit {
  @Input() filter: any = {};
  @Input() page: any = {};

  constructor(private params_Service: QueryParamsService) { }

  ngOnInit(): void { }

  setPage(pageNumber: Number) {
    this.page.pageNumber = pageNumber;
    this.params_Service.setQueryParams(this.filter, this.page)
  }

}
