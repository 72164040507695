import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {
  params: HttpParams;
  api = environment.apiEndPoint;
  constructor(private http: HttpClient) {
  }
  getCustomerAutocomplete(data){
    return this.http.post(this.api + '/autocomplete/get_customer_autocomplete', {search_string: data['search_string'], search_method:data['search_method']});
  }
  getOrderAutocomplete(search_string){
    return this.http.post(this.api + '/autocomplete/get_order_autocomplete', {search_string: search_string});
  }
}
