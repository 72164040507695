import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-code-check-box-btn',
  templateUrl: './bar-code-check-box-btn.component.html',
  styleUrls: ['./bar-code-check-box-btn.component.scss']
})
export class BarCodeCheckBoxBtnComponent implements OnInit {

  isChecked = false;
  constructor() { }

  ngOnInit(): void {
    this.isChecked = JSON.parse(localStorage.getItem('barcodecheck'));
  }

  onChange(event){
    localStorage.setItem('barcodecheck', JSON.stringify(event.target.checked));
  }
}
