import { ChangeDetectionStrategy, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { LogsService } from '../../services/logs.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportIssueComponent implements OnInit {
  pageUrl:any;
 modalRef:BsModalRef
  config: {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-md'
  }
  reportForm:UntypedFormGroup;
  isSubmitted = false;
  constructor(private modalService: BsModalService,
    private logsService:LogsService, private router: Router, private notifier:NotifierService, private ac:ActivatedRoute,
    private fb:UntypedFormBuilder, private spinner:NgxSpinnerService) {
      this.reportForm = this.fb.group({
        message:['', [Validators.required]]
      })
     }


  ngOnInit(): void {
  }


  sendReport(){
   this.isSubmitted = true;
    if(this.reportForm.valid){
      this.spinner.show();
      this.isSubmitted = false;
      const data = { message:this.reportForm.get('message').value, frontend_page: this.pageUrl, backend_page:this.lastApiUrl}
      this.logsService.reportIssue(data).subscribe(res=>{
          this.notifier.notify('success', "Issue Reported");
          this.modalRef.hide();
          this.spinner.hide();
      })
    }



  }


  lastApiUrl:string;
  openModal(template: TemplateRef<any>) {
    this.lastApiUrl = JSON.parse(localStorage.getItem('lastApiUrl'));
    this.isSubmitted = false;
    this.pageUrl = location.href;
    this.reportForm.reset();
    this.modalRef = this.modalService.show(template, this.config);
  }

}
