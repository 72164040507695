import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkbox-btn',
  templateUrl: './checkbox-btn.component.html',
  styleUrls: ['./checkbox-btn.component.scss']
})
export class CheckboxBtnComponent implements OnInit {
  isChecked = false;
  constructor() { }

  ngOnInit(): void {
    this.isChecked = JSON.parse(localStorage.getItem('weightCheck'));
  }

  onChange(event){
    localStorage.setItem('weightCheck', JSON.stringify(event.target.checked));
  }

}
