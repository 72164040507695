import { Directive, ElementRef, HostListener } from '@angular/core';
import { SaleOrdersListComponent } from 'src/app/features/home/sale-orders/sale-orders-list/sale-orders-list.component';
@Directive({
  selector: '[appOnBlur]'
})
export class OnBlurDirective {
  charCode = false;
  
  constructor(private host: ElementRef) { }

  @HostListener("keypress", ["$event"])
  onKeypress($event) {

    if ($event.charCode === 13) {
      this.charCode = true;
      return;
    }
  }


  @HostListener("blur")
  onBlur() {

    if (this.charCode) {
      return;
    }
    this.host.nativeElement.value = '';
   
  }
}
