<!-- <div class="multi-select-dropdown">

  <ng-multiselect-dropdown
    class="multi-select-custom searching-multiSelect"
    [ngClass]="{'custom-filter': searching}"
    [placeholder]="'Customer'"
    formControlName="customer_ids"
    [settings]="dropdownSettings"
    [data]="customerList"
    (onFilterChange)="customerFilterChange($event, 'name')"
    >
  </ng-multiselect-dropdown>
  <span class="searching-text" *ngIf="searching">Searching......</span>
</div> -->

<div class="MultiSelectDropDown" #multiDropDown [class.custom-class]="customClass">
  <div class="CommonDropDown">
    <div class="position-relative main-drop-dwon">
      <div
        class="ms-parent js-brand-select js-styled-select select-processed"
        title=""
      >
        <button
          type="button"
          class="ms-choice select-btn"
          (click)="ShowHide(true)"
          [disabled]="disabled"
        >
          <span *ngIf="selectedItems.length == 0" class="just-placeholder">{{placeholder}}</span>
          <span *ngIf="selectedItems.length < 3 && selectedItems.length > 0">
            <span *ngFor="let selected of selectedItems; let i =index" class="value-placeholder">
              {{selected[bindLabel]}} {{(selectedItems.length > 1 && i+1 !== selectedItems.length) ? ',':''}}
            </span>
          </span>
         <span *ngIf="selectedItems.length > 2">{{selectedItems.length + ' of ' + filteredList.length + ' selected'}}</span>

          <div class="icon-caret"></div>
        </button>

          <div class="ms-drop bottom show-drop" *ngIf="isShow">
            <div class="ms-search">
              <input
                type="text"
                placeholder="search"
                (input)="search($event)"
              />
            </div>
            <div class="listwrapper overflow-auto">
              <ul>

                  <li
                    *ngFor="let item of filteredList; let i = index"
                  >
                    <div class="custom-control custom-checkbox checkbox_Padding" style="padding-left: '10px'">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [value]="item[bindValue]"
                        [checked]="item['isSelected']"
                        [name]="fieldName + i"
                        [id]="item[bindLabel] + i"
                        (change)="selectItem($event, item)"
                      />
                      <label
                       style="width:'100%'"
                        class="custom-control-label"
                        [htmlFor]="item[bindLabel] + i"
                      >
                        {{item[bindLabel]}}
                      </label>
                    </div>
                  </li>



                  <li *ngIf="!filteredList?.length" class="ms-no-results">No matches found</li>

              </ul>

            </div>
          </div>

      </div>
    </div>
  </div>
</div>
