import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VerifyPhoneComponent } from './accounts/components/verify-phone/verify-phone.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { RouteGuard } from './shared/auth/route.guard';
import { PrintingInvoicesComponent } from './features/home/adminPages/orders/printing-invoices/printing-invoices.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
    canActivate: [RouteGuard],
  },
  {
    path: 'home',
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'verify',
    component: VerifyPhoneComponent
  },
  {
    path: 'orders/invoiceView',
    component: PrintingInvoicesComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
