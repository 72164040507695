<div class="accounts-main">
    <div class="accounts-header">
        <div class="logo">
            <img src="/assets/images/zekli-logo.png" loading="lazy">
        </div>

        <div class="navigation">
            <!-- <a [routerLink]="['/register']" routerLinkActive="active" i18n>Register</a> -->
            <a [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" i18n>Login</a>
        </div>
    </div>
</div>
