import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {

  @Input() totalRecords = 0;
  @Input() recordsPerPage = 0;


  @Input() totalPages: number = 1;
  @Output() onPageChange: EventEmitter<number> = new EventEmitter();
  @Input() activePage: number;
  public pages: number[] = [];

  ngOnChanges(): any {
    this.getPageCount();

    this.activePage = 1;
    this.onPageChange.emit(1);
  }

  private getPageCount(): void {
    this.pages = Array(this.totalPages).fill(0)

  }

  onClickPage(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.pages.length) {
      this.activePage = pageNumber;
      this.onPageChange.emit(this.activePage);
    }
  }

}
