import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarRatingComponent implements OnInit {
  @Input('rating') rating?: any;
  @Input('fontSize') fontSize?: string = 'x-large';
  @Input('width') width?: string = '22px';
  halfClass1:boolean= false;
  halfClass2:boolean= false;
  halfClass3:boolean= false;
  halfClass4:boolean= false;
  halfClass5:boolean= false;
  fullClass1:boolean= false;
  fullClass2:boolean= false;
  fullClass3:boolean= false;
  fullClass4:boolean= false;
  fullClass5:boolean= false;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes:SimpleChanges){
      if(changes['rating'].currentValue){
          this.getRating();
      }
  }

  getRating(){

      if(this.rating < 1){
        this.halfClass1 = true;
      }else if(this.rating == 1){
          this.fullClass1 = true;
      }else if(this.rating > 1 && this.rating < 2){
          this.fullClass1 = true;
          this.halfClass2= true;
      }else if(this.rating == 2){
          this.fullClass1 = true;
          this.fullClass2= true;

      }else if(this.rating > 2 && this.rating < 3){
          this.fullClass1 = true;
          this.fullClass2= true;
          this.halfClass3= true;

      }else if(this.rating == 3){
          this.fullClass1 = true;
          this.fullClass2= true;
          this.fullClass3= true;

      }else if(this.rating > 3 && this.rating < 4){
          this.fullClass1 = true;
          this.fullClass2= true;
          this.fullClass3= true;
          this.halfClass4= true;

      }else if(this.rating == 4){
          this.fullClass1 = true;
          this.fullClass2= true;
          this.fullClass3= true;
          this.fullClass4= true;

      }else if(this.rating > 4 && this.rating < 5){
          this.fullClass1 = true;
          this.fullClass2= true;
          this.fullClass3= true;
          this.fullClass4= true;
          this.halfClass5= true;

      }else if(this.rating == 5){
          this.fullClass1 = true;
          this.fullClass2= true;
          this.fullClass3= true;
          this.fullClass4= true;
          this.fullClass5= true;
      }

  }

}
