import { Compiler, Component, isDevMode, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VersionService } from './shared/services/version.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Warehouse Portal';
  devmode: boolean;
  has_ui_update: boolean = false
  is_localhost: boolean = false
  currentApplicationVersion = environment.appVersion;

  constructor(private _compiler: Compiler, private version: VersionService){}

  ngOnInit(): void {
    this._compiler.clearCache();
    this.check()
    this.version.hasUpdate$.subscribe(val => {
      this.has_ui_update = val
    })

    if (window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1') {
      this.is_localhost = true
    }
  }

  check() {
    console.log("dev mode", isDevMode());
    if (isDevMode()) {
      this.devmode = true;
    } else {
      this.devmode = false;
    }
  }

  checkForNewBuild(){
   let localVersion = JSON.parse(localStorage.getItem('version'));
    if(this.currentApplicationVersion != localVersion){

      this._compiler.clearCache();

      localStorage.setItem('version', JSON.stringify(this.currentApplicationVersion));
      window.location.href = window.location.href;
    }else{


    }
  }

  hardReload() {
    this.version.removeCacheReload(window.location.origin)
  }
}
