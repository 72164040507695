import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  setQueryParams(filter, page, url?: string) {
    const queryParams = Object.entries({
      ...filter,
      pageNumber: page.pageNumber,
      per_page: page.per_page,
    })
      .filter(
        ([key, value]) =>
          value !== null && value !== undefined && value.toString() !== ''
      )
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    if (url) {
      this.router.navigate([url], {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: '',
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: '',
      });
    }
  }
}
