 <div class="form-outline">

                <ng-multiselect-dropdown
                  class="multi-select-custom searching-multiSelect"
                  [ngClass]="{'customer-select-sm':customClassSm,'custom-filter': searching}"

                  [placeholder]="'Customer'"
                  [(ngModel)]="selectedcustomer_ids"
                  [settings]="dropdownSettings"
                  [data]="customerList"
                  (onFilterChange)="customerFilterChange($event, 'name')"
                  (onSelect)="onItemSelect($event)"
                  #searchdropdown
                  >
                </ng-multiselect-dropdown>
                <span class="searching-text" *ngIf="searching">Searching......</span>
</div>
