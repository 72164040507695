import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSelectText]'
})
export class SelectTextDirective {

  constructor(private host: HTMLInputElement, private elementRef: ElementRef) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.focus();
    this.host.select();

  }


}
