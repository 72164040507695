import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any[], term: string, key: string): unknown {
    if(!term){
      return value
    }
    return value.filter(entry => {
      if(key) {
        return entry[key].toLowerCase().includes(term.toLowerCase())
      } else {
        return entry.toLowerCase().includes(term.toLowerCase());
      }
    });
  }

}
