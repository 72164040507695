import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Pagination } from '../models/Pagination.model';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  params: HttpParams
  api = environment.apiEndPoint
  constructor(private http: HttpClient) {
  }


  getOrderLOcationLogsList(page, filters) {
    // if (Object.keys(filters).length === 0) {
    //   filters['status'] = "incomplete";
    // }

    let params = { page: page.pageNumber, per_page: page.per_page, ...filters }
    return this.http.get(this.api + '/stocks/logs', { params: params });
  }

  userSuggestion(username) {
    let params = { username }
    return this.http.get(this.api + '/suggest/user', { params: params });
  }

  stockLogsForEachItem(stock_id) {
    let params = { stock_id }
    return this.http.get(this.api + '/stocks/logs', { params: params });
  }

  manaualPrintLogs(stock_id){
    
    return this.http.post(this.api + '/stocks/reprinted_sticker', { stock_id });
  }

  getAdminLogs(page, filters){
    let params = { page: page.pageNumber, per_page: page.per_page, ...filters }
    return this.http.get(this.api + '/logs', { params: params });
  }

  getSearchKeyList(){
    return this.http.get(this.api + '/logs/search_keys');
  }

  getMessageDetails(data, page:Pagination){
    let params = {...data, page: page.pageNumber, limit: page.limit}
    return this.http.get(this.api + '/logs/details', {params:params});
  }

  reportIssue(data){
    return this.http.post(this.api+'/report', data);
  }
}
