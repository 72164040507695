import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrdersService } from 'src/app/shared/services/orders.service';

@Component({
  selector: 'app-printing-invoices',
  templateUrl: './printing-invoices.component.html',
  styleUrls: ['./printing-invoices.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrintingInvoicesComponent implements OnInit {
  tableContent:any;
  order_ids:any;
  type:any;

  constructor(private route:ActivatedRoute, private ordersService: OrdersService, private spinner:NgxSpinnerService) {  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(res=>{
        this.order_ids =res['order_ids'];
        this.type =res['type'];

        if(typeof(this.order_ids) == 'string'){
          this.order_ids =[ this.order_ids];
        }
        this.getInvoice();
    })


  }


getInvoice(){
  this.spinner.show();
  const data = {order_ids: this.order_ids}
  if( this.type  === 'invoice'){
    this.ordersService.printInvoice(data).subscribe((res) => {
      this.tableContent = res['html'];
      setTimeout(()=>{
        window.print();
        window.onafterprint = () => window.close();
      }, 1000);
      this.spinner.hide();
    });
  }else if( this.type === 'shipping'){
    this.ordersService.printShippingList(data).subscribe((res:any) => {
      this.tableContent = res['html'];

      setTimeout(()=>{
        window.print();
        window.onafterprint = () => window.close();
      }, 1000);
      this.spinner.hide();
    });
  }

}

}
