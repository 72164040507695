<nav aria-label="page navigation example paginationCustom">  
    <ul class="pagination"> 
      <li class="page-item" (click)="onClickPage(1)" [class.disabled]=" activePage=== 1"><a class="page-link" href="javascript:void(0);">
        ««</a></li>  
      <li class="page-item" (click)="onClickPage(activePage - 1)" [class.disabled]=" activePage=== 1"><a class="page-link" href="javascript:void(0);">«  
          </a></li>  
      <li class="page-item pageof">
        <a class="page-link" href="javascript:void(0);">Page <span> {{activePage}}</span> of <span>{{pages.length}}</span> </a>
      
      </li>  
      <li class="page-item" [class.disabled]="pages.length === activePage" (click)="onClickPage(activePage + 1)" ><a class="page-link" href="javascript:void(0);">»</a></li>  
      <li class="page-item" (click)="onClickPage(pages.length)" [class.disabled]="pages.length === activePage"><a class="page-link" href="javascript:void(0);"> »»</a></li>  

    </ul>  
  </nav> 

  