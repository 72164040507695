
  <button class="btn btn-info btn-sm report_btn" type="button"
  (click)="openModal(messageModal)"><i class="fa fa-exclamation-circle mr-1 "></i> Report Issue</button>


<ng-template #messageModal>
    <div class="modal-header">
        <div class="pull-left">
            <h4 class="modal-title ">Report an Issue</h4>
        </div>
    
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="reportForm">
        <div class="report-message">
            
                <div class="form-group">
                    <label>Message</label>
                    <textarea appAutoFocus class="form-control" formControlName="message" style="height: 140px;"></textarea>

                    <div *ngIf="isSubmitted && reportForm.get('message').errors?.required" class="error">
                            <small>message is required</small>
                    </div>
                </div>
        </div>
    
        <div class="filter-button justify-content-start mt-4">
            <button class="success-btn Search-btn" type="submit"
                (click)="sendReport()">Report</button>
            <button class="reset-btn ml-2" type="button" (click)="modalRef?.hide()">Cancel</button>
        </div>
    </form>
    </div>
</ng-template>