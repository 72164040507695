  <div class="row mt-4">
    <div class="col-lg-8 col-md-10 ml-auto mr-auto text-center">
      <div class="pagination-custom">
        <button (click)="setPage(page.pageNumber - 1)" [disabled]="!page.previousPage || page.previousPage === null">
          <i class="fa fa-angle-double-left"></i> Prev
        </button>
        <button (click)="setPage(page.pageNumber + 1 )" [disabled]="!page.nextPage || page.nextPage === null">Next
          <i class="fa fa-angle-double-right"></i></button>
      </div>
    </div>
  </div>