import { AfterContentInit, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject, Subscription, of } from 'rxjs';
import { debounceTime, tap, switchMap, catchError, map } from 'rxjs/operators';
import { AutocompleteService } from '../../services/autocomplete.service';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-customer-multi-select',
  templateUrl: './customer-multi-select.component.html',
  styleUrls: ['./customer-multi-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => CustomerMultiSelectComponent)
  }]
})
export class CustomerMultiSelectComponent implements OnInit, AfterContentInit ,ControlValueAccessor, OnChanges,OnDestroy {
  @Input() public control: AbstractControl;
  @Input() public singleSelectioninput?: boolean = false;
  @Input() public closeDropDownOnSelection?: boolean = false;
  @Input() public customClassSm?: boolean =false;
  private customerListSubject: Subject<any> = new Subject<any>();
  customerList: any = [];
  searching: boolean = false;
  selectedcustomer_ids:any;

  private propagateChange = (_: any) => { };
  subscription:Subscription;
  dropdownSettings: IDropdownSettings;
  constructor(private autocompleteService: AutocompleteService,) {
    this.dropdownSettings = {
      singleSelection: this.singleSelectioninput,
      idField: 'id',
      allowRemoteDataSearch: true,
      textField: 'caption',
      allowSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'No data available',
      itemsShowLimit: 1,
      closeDropDownOnSelection:this.closeDropDownOnSelection
    }
  }

  ngOnInit(): void {

   this.subscription = this.customerListSubject.pipe(
      debounceTime(300),
      tap(() => this.searching = true),
      switchMap((event: any) => {
        return this.autocompleteService.getCustomerAutocomplete(event).pipe(
          catchError(error => {
            return of([]);
          }),
          tap(() => this.searching = false)
        );
      }),
      map((res: any) => {
        const defaultOption = [{ id: -1, caption: 'no data available....' }];
        return res && res.length >= 1 ? res : defaultOption;
      })
    ).subscribe(res=> {
      this.customerList = res
    })

  }

  ngAfterContentInit(): void {
  }

  ngOnChanges(){
    this.dropdownSettings = {
      singleSelection: this.singleSelectioninput,
      idField: 'id',
      allowRemoteDataSearch: true,
      textField: 'caption',
      allowSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'No data available',
      itemsShowLimit: 1,
      closeDropDownOnSelection:this.closeDropDownOnSelection
    }
  }


  setDisabledState?(isDisabled: boolean): void {
  }
  writeValue(obj: any): void {
    this.selectedcustomer_ids = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  customerFilterChange(event, type): void {
    const data  = { search_string:event, search_method: type}
    if(event){
      this.customerListSubject.next(data)
    }

  }

  onItemSelect(event:any){
    this.propagateChange(this.selectedcustomer_ids);
  }

  ngOnDestroy(){
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
