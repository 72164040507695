import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-internet-down-modal',
  templateUrl: './internet-down-modal.component.html',
  styleUrls: ['./internet-down-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternetDownModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {
  }





}
