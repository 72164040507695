import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-multi-select-dropdown',
  templateUrl: './custom-multi-select-dropdown.component.html',
  styleUrls: ['./custom-multi-select-dropdown.component.scss']
})
export class CustomMultiSelectDropdownComponent implements OnInit {
  @Input() list:any[] =[];
  @Input() bindLabel:string ='';
  @Input() customClass:string ='';
  @Input() placeholder:string ='';
  @Input() defaultValue:string ='';
  @Input() fieldName:string ='';
  @Input() bindValue:string ='';
  @Input() disabled:boolean = false;
  @ViewChild('multiDropDown') multiDropDown:ElementRef<any>;
  isShow = false;
  selectedItems:any[]=[];
  filteredList:any[]=[];
  constructor() { }

  ngOnInit(): void {
    this.filteredList = this.list;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.list && this.list) {
      this.list.map((item:any)=> item.isSelected = false);

        let type =typeof this.defaultValue;
        let selected:any[]=[];
        let defaultObj:any;

      if(type === "string"){
        selected  = this.defaultValue.split(",");
        defaultObj = this.list.filter((item: any) =>{ return selected?.includes(item[this.bindValue].toString())});
      }else if(type === 'number'){
        selected.push(this.defaultValue);
        defaultObj = this.list.filter((item: any) =>{ return selected?.includes(item[this.bindValue])});
      }

      if (defaultObj?.length > 0) {
        defaultObj?.map((item:any)=>item['isSelected'] = true);
        this.selectedItems = defaultObj;
      } else {
        this.selectedItems = [];

      }
    }
  }

  @HostListener('document:mousedown', ['$event'])
   handleClickOutside = (event: any) => {
    if (this.multiDropDown.nativeElement && !this.multiDropDown.nativeElement.contains(event.target)) {

        this.isShow = false;
    }
  }

 ShowHide = (value: boolean) => {
 this.isShow = !this.isShow;
  // setFilteredList(list);
}

 selectItem = (event: any, item: any) => {
  if(event.target.checked) {
    this.filteredList?.map((value:any) =>{if(value[this.bindValue] == item[this.bindValue]){return value['isSelected']=true;} });
    this.selectedItems.push(item);

  }else{
    this.filteredList?.map((value:any) =>{if(value[this.bindValue] == item[this.bindValue]){return value['isSelected']=false;}});

    this.selectedItems = this.selectedItems.filter((item: any) => item[this.bindValue] != event.target.value);

  }

};

 search = (event: any) => {

  let value = event.target.value;

  // if (event.target.selectionStart > 1) {


  //   this.productsList = new Observable((observer: Observer<string | undefined>) => {
  //     observer.next(event.target.value);
  //   }

  //   ).pipe(
  //     switchMap((query: string) => {
  //       if (query) {
  //         return this.couponService.getproducts({search_str:event.target.value}).pipe(
  //           map((data: any) => data || []),
  //         );
  //       }

  //       return of([]);
  //     })
  //   );
  // }

//   let filtered = this.list.filter((item: any) =>
//   item[this.bindLabel].replace(/\s/g, '').toLowerCase().startsWith(value.replace(/\s/g, '').toLowerCase())
//   );
//  this.filteredList =  filtered;
};

}
