import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appDoubleClick]'
})
export class DoubleClickDirective {

  @Input()
  debounceTime = 500;

  @Output()
  debounceClick = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription;

  constructor(private el: ElementRef) { }

  ngOnInit() {

    this.subscription = this.clicks.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => this.debounceClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    const buttonElement = this.el.nativeElement as HTMLButtonElement;

    // Disable the button
    buttonElement.disabled = true;

    // Enable the button after the specified delay time
    setTimeout(() => {
      buttonElement.disabled = false;
    }, 2000);
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
