import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  api = environment.apiEndPoint;
  constructor(private http: HttpClient) {}
  orersList(page, filterResult) {
    let params = {
      page: page.pageNumber,
      per_page: page.per_page,
      ...filterResult,
    };

    return this.http.get(this.api + '/admin/orders', { params: params });
  }

  getEmailsList() {
    return this.http.get(this.api + '/admin/orders/emails');
  }

  showOrder(data: any) {
    // let params = {page: page.pageNumber , per_page: page.per_page, ...filterResult}
    return this.http.get(this.api + '/admin/orders/show', { params: data });
  }
  addQuickShipping(data: any) {
    return this.http.post(
      this.api + '/admin/orders/update/shipping_address',
      data
    );
  }
  addWarehouse(data: any) {
    return this.http.post(this.api + '/admin/orders/update/warehouse', data);
  }
  addHistory(data: any) {
    return this.http.post(this.api + '/admin/orders/add/history', data);
  }
  createInvoice(data: any) {
    return this.http.post(this.api + '/admin/orders/create_invoice', data);
  }

  printInvoice(data: any) {
    // return this.http.post(this.api + '/admin/orders/print_invoice',data);
    return this.http.get(this.api + '/orders/invoice', { params: data });
  }

  printShippingList(data: any) {
    return this.http.post(this.api + '/admin/orders/print_shipping', data);
  }

  getCountries() {
    return this.http.get(this.api + '/shipments/countries');
  }
  getStates(country_id: any) {
    return this.http.get(this.api + '/admin/orders/zones', {
      params: country_id,
    });
  }
  getStatuses() {
    return this.http.get(this.api + '/admin/orders/order_statuses');
  }
  getAirWayBilForm(data: any) {
    return this.http.get(this.api + '/admin/orders/airway_form', {
      params: data,
    });
  }
  createAirWayBil(data: any) {
    return this.http.post(this.api + '/admin/orders/create_awb', data);
  }

  createAWB(data: any) {
    return this.http.post(this.api + '/admin/orders/awb/create', data);
  }
  awbCreationlock(data:any){
    return this.http.post(this.api + '/admin/orders/lock_weight', data);
  }
  awbCreationUnlock(data:any){
    return this.http.post(this.api + '/admin/orders/unlock_weight', data);
  }

  trustPilot(data:any){
    return this.http.post(this.api + '/admin/orders/trustpilot_export', data);
  }
  productsWeightList(data: any) {
    return this.http.get(this.api + '/admin/orders/product_weight', {
      params: data,
    });
  }

  saveAirWayBillAttatchements(data: any) {
    return this.http.post(this.api + '/admin/orders/save_attachments', data);
  }
  addShippingCost(data: any) {
    return this.http.post(this.api + '/admin/orders/shipping_cost', data);
  }
  customerAutocomplete(data: any) {
    return this.http.get(this.api + '/admin/customers/autocomplete', {
      params: data,
    });
  }

  shippingMethodFilters() {
    return this.http.get(
      this.api + '/admin/orders/payment_and_shipping_methods'
    );
  }

  exportFile(data: any) {
    return this.http.post(this.api + '/admin/orders/export', data);
  }
  orderSummarry(data: any) {
    return this.http.get(this.api + '/admin/orders/summery', { params: data });
  }

  deleteAttatchmentfile(fileId) {
    // const data = { id:fileId}
    return this.http.post(this.api + '/admin/orders/delete_attachment', {
      id: fileId,
    });
  }
  updateAwbNumber(data: any) {
    return this.http.post(this.api + '/admin/orders/awb_update', data);
  }
  deleteAwbNumber(order_id: number) {
    return this.http.post(this.api + `/admin/orders/awb/delete`,{order_id});
  }

  printAwb(data: any) {
    return this.http.get(
      this.api + `/admin/orders/download_awb/${data.order_id}`,
      { params: { type: data.type } }
    );
  }
  booking(data: any) {
    return this.http.post(this.api + `/admin/orders/awb/book_pickup`, data);
  }

    importOrder(data: any) {
      return this.http.post(this.api + '/admin/orders/import_file', data);
    }
    importOrderfiltersList(data: any) {
      return this.http.get(this.api + '/admin/orders/import_file/lists', {params:data});
    }

    exportImportorderTemplate() {
      return this.http.get(this.api + '/admin/orders/import_file/template');
    }
    getTransactionDetails(data:any) {
      return this.http.get(this.api + '/admin/orders/payment_transaction_details', {params:data});
    }
    refundTransaction(data:any) {
      return this.http.post(this.api + '/admin/orders/payment_refund', data);
    }
    awbPredefinedValues(order_id:number) {
      return this.http.get(this.api + '/admin/orders/awb', {params:{order_id}});
    }
}


