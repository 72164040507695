import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RegisterService } from './register.service';
import { catchError, map, retry } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InternetDownModalComponent } from '../components/internet-down-modal/internet-down-modal.component';
import Swal from 'sweetalert2';
import { VersionService } from '../services/version.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {
  bsModalRef?: BsModalRef;

  constructor(private registerService: RegisterService, public http: HttpClient,
    private router: Router, private notifier: NotifierService, private spinner: NgxSpinnerService,
    private modalService: BsModalService, private version: VersionService

  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';

          if(error.error?.type === 'permission_popup'){
            this.router.navigate(['/home']);
          }

          if (error.error?.errors) {
            const errorCat = Object.values(error.error?.errors)
            const allErrors = this.flat(errorCat);

            allErrors.forEach(element => {
              this.notifier.notify('error', element)
            })

          } else if(error.status === 0){
            // this.notifier.notify('error', "Api Failed. Please check server");
            if (!request.headers.get('skip_waiting')) {
              this.version.updateBackendServer('Offline')
              this.openModalWithComponent();
            }

          } else{
            if (error.error?.type == "swal_popup") {
              Swal.fire({
                icon: "error",
                title: error.error?.title ?? "Error",
                text: error.error?.message
              });
            } else {
              if (error.error?.type !== "popup") {
                this.notifier.notify('error', error.error?.message);
              }
            }
          }

          this.spinner.hide();
          if (error instanceof ErrorEvent) {


          }
          else {

            if (error.status === 401) {
              localStorage.clear();
              this.router.navigate(['/']);
            }
          }
          return throwError(error);
        }),
        map(res => {

          return res;
        }))

  }

  flat(arrays: any[]) {
    return [].concat.apply([], arrays)
  }

  openModalWithComponent() {

    this.bsModalRef = this.modalService.show(InternetDownModalComponent);
  }
}
