import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { QueryParamsService } from '../../services/query-params.service';

@Component({
  selector: 'app-per-page-common',
  templateUrl: './per-page-common.component.html',
  styleUrls: ['./per-page-common.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PerPageCommonComponent implements OnInit {

  constructor(private query_params: QueryParamsService) { }

  @Input() page: any = {};
  @Input() filter: any = {};

  ngOnInit(): void {
  }

  perPage(event) {
    let per_page = event.target.value;
    this.page.pageNumber = 1;
    this.page.per_page = per_page;
    this.query_params.setQueryParams(this.filter,this.page)
  }

}
